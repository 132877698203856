@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@keyframes scaleUpDown {
  0% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0;
  }
}

@keyframes expandWidth {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.animate-fadeOut {
  animation: fadeOut 500ms linear;
}

.animate-expandWidth {
  animation: expandWidth 500ms linear;
}

.animateScaleUpDown {
  animation: scaleUpDown 500ms ease-out;
}

.animate-fadeOutAndExpand {
  animation: fadeOut  250ms linear, expandWith  250ms linear;
}


.guitar-chord circle {
  @apply fill-current;
}

.guitar-chord  path {
  @apply stroke-current;
}

.guitar-chord  rect {
  @apply stroke-current fill-current;
}

.guitar-chord text {
  @apply fill-current;
}

.firebase-emulator-warning {
  display: none;
}

h1 {
  @apply text-3xl;
}

h2 {
  @apply text-2xl;
}

h1 {
  @apply text-xl;
}

p {
  @apply py-4;
}